import axios from '@/plugins/axios'
import { cleanObj } from '@/tools/helpers'

const state = () => ({
  transactions: {
    page: 1,
    value: [],
    count: 0,
    limit: 30,
  },
  users: {
    page: 1,
    value: [],
    count: 0,
    limit: 30,
  },

  listOwnerRoles: [],
  listOwnerPermissions: null,
})

const getters = {
  user: store => store.user,
  users: store => store.users,
  transactions: store => store.transactions,
  listOwnerRoles: store => store.listOwnerRoles,
  listOwnerPermissions: store => store.listOwnerPermissions,
}

const actions = {
  fetchListUsers(
    { commit },
    {
      limit = 10, page = 1, telegramId = undefined, userName = undefined,
    } = {},
  ) {
    const requestQuery = {
      limit,
      page,
      telegramId,
      userName,
    }
    const params = cleanObj(requestQuery)

    return axios({ method: 'GET', url: '/users', params }).then(response => {
      commit('setUsers', { users: response.data, params })

      return response?.data
    })
  },

  createUser(store, userInfo) {
    return axios({
      method: 'POST',
      url: '/users/user',
      data: userInfo,
    })
  },

  fetchUserById({ commit }, id) {
    return axios({ method: 'GET', url: `/users/user/${id}` }).then(response => {
      commit('setUser', response.data)

      return response
    })
  },

  fetchUserRoles({ commit }) {
    return axios({ method: 'GET', url: '/users/owner-roles' }).then(response => {
      const { data } = response
      const roles = []
      const permissions = []
      let listPermissions = []

      if (data.length) {
        data.forEach(el => {
          const role = { ...el, permissions: JSON.parse(el.permissions) }
          roles.push(role)
          listPermissions.push(JSON.parse(el.permissions))
        })
      }
      listPermissions = listPermissions.flat()
      for (const str of listPermissions) {
        if (!permissions.includes(str)) {
          permissions.push(str)
        }
      }
      commit('setListOwnerRoles', roles)
      commit('setListOwnerPermissions', permissions)
      localStorage.setItem('permissions', JSON.stringify(permissions))
      localStorage.setItem('roles', JSON.stringify(roles))

      return response
    })
  },

  blockUser({ commit }, { id } = {}) {
    return axios({ method: 'POST', url: `/users/block/${id}` }).then(response => {
      commit('updateUserItemInList', response.data)

      return response
    })
  },

  activateUser({ commit }, { id } = {}) {
    return axios({ method: 'POST', url: `/users/activate/${id}` }).then(response => {
      commit('updateUserItemInList', response.data)
      return response
    })
  },

  toggleAdminView({ commit }, { id } = {}) {
    return axios({ method: 'POST', url: `/users/toggle-admin-view-access/${id}` }).then(response => {
      commit('updateUserAdminViewAccess', { id })

      return response
    })
  },

  updateUserLimits(store, { id, params } = {}) {
    console.log('params', params)
    return axios({ method: 'PUT', url: `/users/${id}/limits`, data: params }).then(response => response)
  },

  fetchUserWallets(store, { id } = {}) {
    return axios({ method: 'GET', url: `/users/${id}/wallets` }).then(response => response)
  },
  fetchUserLimits(store, { id } = {}) {
    return axios({ method: 'GET', url: `/users/${id}/limits` }).then(response => response)
  },
  fetchUserTransactions(
    { commit },
    {
      id,
      page = 1,
      limit = 30,

      coinId = undefined,
      type = undefined,
      status = undefined,
      userAddress = undefined,
      userTelegramName = undefined,
    } = {},
  ) {
    const requestQuery = {
      page,
      limit,

      coinId,
      type,
      status,
      userAddress,
      userTelegramName,
    }
    const params = cleanObj(requestQuery)

    return axios({ method: 'GET', url: `/users/${id}/transactions`, params }).then(response => {
      commit('setTransactions', { transactions: response.data, params })

      return response
    })
  },

  toggleCeFi({ commit }, { id } = {}) {
    return axios({ method: 'POST', url: `/users/toggle-cefi/${id}` }).then(response => {
      commit('updateUserItemInList', response.data)

      return response
    })
  },

  getBoosts(store, { id } = {}) {
    return axios({ method: 'GET', url: `/users/${id}/boosts` }).then(response => response)
  },

  fetchRefundBoost(store, { userId, boostId, packId } = {}) {
    return axios({ method: 'POST', url: `/users/${userId}/boosts/${boostId}/refund`, data: { packId } }).then(
      response => response,
    )
  },
}

const mutations = {
  setUser(state, user) {
    state.user = user
  },
  setUsers(state, { users, params } = {}) {
    const { limit, page } = params

    state.users.value = users.items
    state.users.count = users.total
    state.users.limit = limit
    state.users.page = page
  },
  setListOwnerRoles(state, data) {
    state.listOwnerRoles = data
  },
  setListOwnerPermissions(state, data) {
    state.listOwnerPermissions = data
  },

  updateUserItemInList(state, user) {
    const listDataIndex = state.users?.value?.findIndex(item => item?.id === user?.id)

    if (listDataIndex === -1) return

    state.users.value[listDataIndex] = { ...user }
    state.users.value.splice(state.users.value?.length)
  },

  updateUserAdminViewAccess(state, user) {
    const listDataIndex = state.users?.value?.findIndex(item => item?.id === user?.id)

    if (listDataIndex === -1) return

    state.users.value[listDataIndex].hasViewerRole = !state.users.value[listDataIndex].hasViewerRole
    state.users.value.splice(state.users.value?.length)
  },

  setTransactions(state, { transactions, params } = {}) {
    const { limit, page } = params

    state.transactions.value = transactions.items || []
    state.transactions.count = transactions.total
    state.transactions.limit = limit
    state.transactions.page = page
  },
  clearTransactions(state) {
    state.transactions.value = []
    state.transactions.count = 0
    state.transactions.limit = 30
    state.transactions.page = 1
  },
}

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
}
